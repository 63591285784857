import {QuestionDifficulty} from '@hconnect/common/types'
import {toString, toLower} from 'lodash'
import {Params} from 'react-router'

export const ROOT = '/'
const PLANT_ROOT = '/:plantId'
export const DASHBOARD = `${PLANT_ROOT}/dashboard`
export const PLANT_SECTION = `${PLANT_ROOT}/section/:sectionId`
export const PLANT_SUB_SECTION = `${PLANT_ROOT}/subsection/:sectionId/:topicId`
export const ACTIONS = `${PLANT_ROOT}/actions`
export const ERROR_403 = '/error403'

export const plantRoutes = [DASHBOARD, PLANT_SECTION, PLANT_SUB_SECTION, ACTIONS]

export interface PathParameters extends Params {
  plantId?: string
  sectionId?: string
  topicId?: string
}

export const questionDifficultyFromString = (
  value: string | undefined
): QuestionDifficulty | undefined => {
  const valueLower = toLower(value)
  return Object.values(QuestionDifficulty).find((v) => toLower(toString(v)) === valueLower)
}
