import {Link} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

export const TITLE_MARGIN_BOTTOM = 3

type ReferencesLinkProps = {
  guidanceLink: string
}

export const ReferencesLink: FC<ReferencesLinkProps> = ({guidanceLink}) => {
  const {t} = useTranslation()

  return (
    <Link
      color={'primary.contrastText'}
      href={guidanceLink}
      target="_blank"
      rel="noopener noreferrer"
      variant="caption"
      data-test-id="question-detail-link"
      sx={{
        display: 'inline-flex',
        mb: TITLE_MARGIN_BOTTOM,
        ml: 3,
        fontSize: 16,
        '&:hover': {
          textDecoration: 'underline'
        }
      }}
    >
      {t('questionnaire.moreInformation')}
    </Link>
  )
}
